<template>
  <v-container fluid grid-list-xl>
    <v-layout wrap row align-center>

      <v-flex xs12 sm12 d-flex>
        <v-layout wrap row align-center>

          <v-flex xs12 sm12 d-flex>
            <v-layout wrap row align-center>
              <v-flex xs12 sm12 d-flex>
                <v-text-field
                    v-model="menuSelect.jobSearch"
                    :label=translations.whatJobDoYouSearch
                    :placeholder=translations.placeholderJobString
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm12 d-flex>
            <v-layout wrap row align-center>
              <v-flex xs6 sm6 d-flex>
                <v-combobox
                    v-model="menuSelect.region.kraj"
                    :items="menu.region.kraj"
                    :label=translations.chooseRegion
                    @change="filterOkres"
                ></v-combobox>
              </v-flex>
              <v-flex xs6 sm6 d-flex>
                <v-combobox
                    v-model="menuSelect.region.okres"
                    :items="menu.region.okres"
                    :label=translations.chooseCity
                    @focus="filterOkres"
                    multiple
                ></v-combobox>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 d-flex>
            <v-layout wrap row align-center>
              <v-flex xs12 sm8 d-flex>
                <v-text-field
                    v-model="menuSelect.salary"
                    :label=translations.minimalSalary
                    placeholder="20 000"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 d-flex>
                <v-select
                    item-text="translated_lang"
                    item-value="id"
                    v-model="menuSelect.salaryType"
                    :items="menu.salaryType"
                    :label=translations.salaryType
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm12 d-flex>
            <v-expansion-panel>
              <v-expansion-panel-content>
                <template v-slot:header>
                  <div>{{ translations.minimalEducation }}</div>
                </template>
                <v-layout row wrap>
                  <v-flex v-for="obj in menu.min_lvl_eduction" sm3 xs6>
                    <v-checkbox :key="menu.min_lvl_eduction.id"
                                :label="obj.translated_lang"
                                :value="obj.id"
                                v-model="menuSelect.min_lvl_eduction">
                    </v-checkbox>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>

          <v-flex xs12 sm12 d-flex>
            <v-expansion-panel>
              <v-expansion-panel-content>
                <template v-slot:header>
                  <div>{{ translations.lawyerRelation }}</div>
                </template>
                <v-layout row wrap>
                  <v-flex v-for="obj in menu.labor_relations" sm3 xs6>
                    <v-checkbox
                        :key="menu.labor_relations.id"
                        :label="obj.translated_lang"
                        :value="obj.id"
                        v-model="menuSelect.labor_relations">
                    </v-checkbox>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>

          <v-flex xs12 sm12 d-flex>
            <v-expansion-panel>
              <v-expansion-panel-content>
                <template v-slot:header>
                  <div>{{ translations.shifts }}</div>
                </template>
                <v-layout row wrap>
                  <v-flex v-for="obj in menu.smennost" sm3 xs6>
                    <v-checkbox
                        :key="menu.smennost.id"
                        :label="obj.translated_lang"
                        :value="obj.id"
                        v-model="menuSelect.smennost">
                    </v-checkbox>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>

          <v-flex xs12 sm12 d-flex>
            <v-expansion-panel>
              <v-expansion-panel-content>
                <template v-slot:header>
                  <div>{{ translations.suitFor }}</div>
                </template>
                <v-layout row wrap>
                  <v-flex v-for="obj in menu.suitFor" sm4 xs5>
                    <v-checkbox
                        :key="menu.suitFor.id"
                        :label="obj.translated_lang"
                        :value="obj.id"
                        v-model="menuSelect.suitFor">
                    </v-checkbox>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>

          <v-flex xs12 sm12 d-flex>
            <v-expansion-panel>
              <v-expansion-panel-content>
                <template v-slot:header>
                  <div> {{ translations.jobAdvantages }} </div>
                </template>
                <v-layout row wrap>
                  <v-flex v-for="obj in menu.advantages" sm4 xs5>
                    <v-checkbox
                        :key="menu.advantages.id"
                        :label="obj.translated_lang"
                        :value="obj.id"
                        v-model="menuSelect.advantages">
                    </v-checkbox>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>

          <v-flex xs12 sm12 d-flex>
            <v-layout wrap row align-center>
              <v-flex xs12 sm12 d-flex>
                <v-text-field
                    v-model="menuSelect.referenceNumber"
                    :label=translations.placeholderNumber
                    placeholder="13182070790"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>

        </v-layout>
      </v-flex>

      <v-flex xs12 sm12 d-flex v-if="this.$store.getters.getPagination !== false">
          <span>
            {{ translations.page }}: {{ this.$store.getters.getPagination["x-pagination-current-page"] }},
            {{ translations.numberCount }}: {{ this.$store.getters.getPagination["x-pagination-total-count"] }},
            <span v-if="typeof this.$store.getters.getSelectedMenuItem.region.kraj === 'object'">
              {{ translations.regionChosen }}: {{ this.$store.getters.getSelectedMenuItem.region.kraj.text }}
            </span>
          </span>
      </v-flex>
      <v-flex xs12 sm12 d-flex>
        <v-btn block outline color="indigo" @click="search" ref="searchToButton" id="vue-vacancies-fbtn" :loading="loading">
          {{ translations.search }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import HTTP from "@/http";

export default {
  data() {
    return {
      langSwitcher: true,
      translations: this.$store.getters.getTranslate,
      found: {},
      loading: false,
      menu: {
        sphera: [],
        skills: [],
        min_lvl_eduction: [],
        salary: null, // mzda
        salaryType: [4380], // typ mzdy
        labor_relations: [],
        smennost: [],
        suitFor: [], // VHODNÉ PRO
        zrakove: [], // ZRAKOVÉ POSTIŽENÍ
        advantages: [], // ПРЕИМУЩЕСТВА РАБОТЫ
        referenceNumber: this.$route.params.id !== undefined ? this.$route.params.id : '', // Справочный номер
        region: {
          kraj: {
            text: "Hlavní město Praha",
            value: 19
          },
          okres: []
        }
      },
      menuSelect: {
        salary: null, // mzda
        salaryType: 4380, // typ mzdy
        skills: [],
        min_lvl_eduction: [],
        labor_relations: [],
        smennost: [],
        suitFor: [], // VHODNÉ PRO
        zrakove: [], // ZRAKOVÉ POSTIŽENÍ
        advantages: [], // ПРЕИМУЩЕСТВА РАБОТЫ
        jobSearch: '', // Какую работу вы ищете?
        referenceNumber: this.$route.params.id !== undefined ? this.$route.params.id : '', // Справочный номер
        region: {
          kraj: {
            text: this.$store.state.language === 'ru' ? "Вся Чехия" : "Вся Чехія",
            value: 9999
          },
          okres: []
        }
      }
    };
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.menu.referenceNumber = this.$route.params.id;
      this.search();
      this.scrollToBottom();
    }
  },
  created() {
    this.regionDropdown();
    HTTP.get(this.$store.state.host + '/vacancies/config/get-by-ciselnik',
        {
          params: {
            ciselnik: 'Dovednost'
          }
        })
        .then((response) => {
          this.menu.skills = response.data.map(obj => obj);
        });

    this.optionsInclude('min_lvl_eduction', '4626,4627,4628,4629,4630,4631,4632,4633,4634,4635,4636,4637,4638,4639,4640,7050');
    this.optionsInclude('salaryType', '4380,4379');
    this.optionsInclude('labor_relations', '4114,4116,4112,4113,4115');
    this.optionsInclude('smennost', '4239,4240,4241,4242,4243,4244,4245,4246,4247,7705');
    this.optionsInclude('suitFor', '4605,4606,4611,4609,4607,4608,4610');
    // this.optionsInclude('zrakove', '4603,4604,4601,4602,5246');
    // this.optionsInclude('foreigners', '4603,4604,4601,4602,5246');
    this.optionsInclude('advantages', '7697, 7698, 7699, 7700, 7701, 7702, 7703, 7704, 7696, 7695');
    this.$store.commit('saveMenuItem', this.menu);
    this.$store.commit('saveSelectMenuItem', this.menuSelect);
  },
  methods: {
    scrollToBottom() {
      // Получаем доступ к рефу элемента
      const elementToScroll = this.$refs.searchToButton.$el;
      // Прокручиваем к элементу
      elementToScroll.scrollIntoView({ behavior: "smooth" }); // "smooth" для плавной анимации, можно заменить на "auto" для мгновенной прокрутки

    },
    optionsInclude(menuItem, ids) {
      HTTP.get(this.$store.state.host + '/vacancies/config/options-include',
          {params: {objectsIds: ids}})
          .then((response) => {
            this.menu[menuItem] = response.data.map(function (obj) {
              let object = JSON.parse(obj.object);
              object.translated_lang = obj.translated_lang;
              return object;
            })
          });
    },
    search() {
      this.loading = true;
      this.$store.commit('search');
      this.loading = false;
    },
    filterOkres() {
      this.menu.region.okres = [];
      this.menuSelect.region.okres = [];
      if (this.menuSelect.region.kraj.value === 9999) {
        this.menu.region.okres = this.menu.region.fullListOkres;
      } else {
        if (this.menuSelect.region.kraj.length !== 0) {
          this.menu.region.fullListOkres.forEach(function (item) {
            if (item.kraj_id === this.menuSelect.region.kraj.value) {
              this.menu.region.okres.push(item);
            }
          }, this);
        } else {
          this.menu.region.okres = this.menu.region.fullListOkres;
        }
      }
    },
    regionDropdown() {
      HTTP.get(this.$store.state.host + '/vacancies/search/region-dropdown')
          .then((response) => {
            this.menu.region.kraj = response.data.kraj;
            this.menu.region.kraj.unshift({text: this.$store.state.language === 'ru' ? "Вся Чехия" : "Вся Чехія", value: 9999});
            this.menu.region.fullListOkres = response.data.okres;
            this.menu.region.okres = response.data.okres;
          });
    }
  }
}
</script>

<style>

.v-expansion-panel__body {
  padding-left: 25px;
}

/* media query for mobiles */
@media only screen and (max-width: 600px) {
  .v-expansion-panel__body {
    padding-left: 0;
  }
}

.v-input--selection-controls {
  margin: 0;
}

.v-menu > .v-menu__content {
  top: 35px !important;
  left: 0 !important;
}

</style>