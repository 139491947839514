import axios from "axios";
import store from "@/store";

// check lang
store.getters.getTranslate;

// Common Base Instance
const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    headers: {
        "Content-Type": "application/json",
        // header for lang
        "Accept-Language": store.state.language,
    },
});

export default HTTP;
