import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./assets/gtm.js";

document.onselectstart = null

import "vuetify/dist/vuetify.min.css";
import vueCustomElement from "vue-custom-element";
Vue.use(vueCustomElement);

Vue.config.productionTip = false;
Vue.use(Vuetify, {
  iconfont: "md" // "mdi" || "mdi" || "fa" || "fa4"
});

if (process.env.NODE_ENV === 'development') {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
} else{
  App.store = store;
  App.router = router;
  Vue.customElement("vue-vacancies", App);
}