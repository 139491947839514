let translations = {
    ru: {
        whatJobDoYouSearch: "Какую работу вы ищете?",
            placeholderJobString: "Например: Менеджер; Пример на чешском: manazer",
            chooseRegion: "Выберите край, например: Прага",
            chooseCity: "Выберите город, например: Прага",
            minimalSalary: "Минимальная зарплата",
            salaryType: "Тип зарплаты",
            minimalEducation: "Минимальное образование",
            lawyerRelation: "Трудовое-правовое отношение",
            shifts: "Смены",
            suitFor: "Подходит для",
            jobAdvantages: "Преимущества работы",
            placeholderNumber: "Справочный номер",
            search: "Поиск",
            page: "Страница",
            numberCount: "Количество вакансий",
            regionChosen: "Выбранный край",
            notFound: "Не найдено объектов под Ваши параметры",
            pagination: {
            company: "Компания",
                timeOfCreation: "Время создания",
                timeLastUpdate: "Время последнего обновления",
                moreInformation: "Больше информации",
                placeholderNumber: "Справочный номер",
        },
        customDialog: {
            commonInfoTitle: "Общая информация",
                company: "Компания",
                timeLastUpdate: "Время последнего обновления",
                placeholderNumber: "Справочный номер",
                salary: "Оклад",
                jobLocation: "Место работы",
                amountVacancies: "Количество предлагаемых мест",
                minimalEducation: "Минимальный уровень образование",
                lawyerRelation: "Трудовое-правовое отношение",
                hoursAtWeek: "Количество часов в неделю",
                shifts: "Смены",
                suitFor: "Подходит для",
                foreigners: "Иностранцы",
                requiredSkills: "Требуемые навыки",
                additionalInfo: "Дополнительная информация",
        }
    },
    uk: {
        whatJobDoYouSearch: "Яку роботу ви шукаєте?",
            placeholderJobString: "Наприклад: Менеджер; Приклад на чеському: manazer",
            chooseRegion: "Виберіть край, наприклад: Прага",
            chooseCity: "Виберіть місто, наприклад: Прага",
            minimalSalary: "Мінімальна зарплата",
            salaryType: "Тип зарплати",
            minimalEducation: "Мінімальна освіта",
            lawyerRelation: "Трудово-правові відносини",
            shifts: "Зміни",
            suitFor: "Підходить для",
            jobAdvantages: "Переваги роботи",
            placeholderNumber: "Довідковий номер",
            search: "Пошук",
            page: "Сторінка",
            numberCount: "Кількість вакансій",
            regionChosen: "Обраний край",
            notFound: "Не знайдено об'єктів за Вашими параметрами",
            pagination: {
            company: "Компанія",
                timeOfCreation: "Час створення",
                timeLastUpdate: "Час останнього оновлення",
                moreInformation: "Більше інформації",
        },
        customDialog: {
            commonInfoTitle: "Загальна інформація",
                company: "Компанія",
                timeLastUpdate: "Час останнього оновлення",
                placeholderNumber: "Довідковий номер",
                salary: "Оклад",
                jobLocation: "Місце роботи",
                amountVacancies: "Кількість пропонованих місць",
                minimalEducation: "Мінімальний рівень освіти",
                lawyerRelation: "Трудово-правові відносини",
                hoursAtWeek: "Кількість годин на тиждень",
                shifts: "Зміни",
                suitFor: "Підходить для",
                foreigners: "Іноземці",
                requiredSkills: "Необхідні навички",
                additionalInfo: "Додаткова інформація",
        }
    }
}

export default translations;
