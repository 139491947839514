import Vue from "vue";
import Vuex from "vuex";
import HTTP from "@/http";
import MD5 from "crypto-js/md5";
import translations from "../store/translations";
import getters from "../store/getters";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    host: process.env.VUE_APP_API_HOST,
    searchPage: null,
    menu: {},
    selectedMenu: {},
    pagination: false,
    pages: false,
    page: 1,
    language: "ru",
    translations: translations
  },
  getters: getters,
  mutations: {
    search(state) {
      let url = `${state.host}/vacancies/search?page=${this.getters.getCurrentPage}&md5=${MD5(JSON.stringify(this.getters.getSelectedMenuItem)).toString()}`;
      window.searchQueryParams = this.getters.getSelectedMenuItem;
      HTTP.post(url,
          this.getters.getSelectedMenuItem)
          .then((response) => {
            this.commit("saveSearchPage", response.data);
            this.commit("savePagination", response.headers);
          });
    },
    saveSearchPage(state, data) {
      this.state.searchPage = data;
    },
    saveMenuItem(state, data) {
      this.state.menu = data;
    },
    saveSelectMenuItem(state, data) {
      this.state.selectedMenu = data;
    },
    savePagination(state, data) {
      this.state.pagination = data;
    },
    savePages(state, data) {
      this.state.pages = data;
    },
    saveCurrentPage(state, data) {
      this.state.page = data;
    },
  },
  actions: {},
  modules: {}
});