<template>
  <div class="main">
    <Menu/>
    <Search/>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Search from "@/components/Search.vue";
  import Menu from "@/components/Menu.vue";

  export default {
    name: "Home",
    components: {
      Search,
      Menu
    }
  };
</script>
