let getters = {
    getSavePage: state => {
        return state.searchPage;
    },
        getMenuItem: state => {
    return state.menu;
},
    getSelectedMenuItem: state => {
    return state.selectedMenu;
},
    getPagination: state => {
    return state.pagination;
},
    getPages: state => {
    return state.pages;
},
    getCurrentPage: state => {
    return state.page;
},
    getAmountPage: state => {
    return parseInt(state.pagination['x-pagination-page-count']);
},
    getAmountObject: state => {
    return parseInt(state.pagination["x-pagination-total-count"]);
},
    getTranslate: state => {
    if (window.location.host.indexOf(".com.ua") !== -1) {
        state.language = "uk";
    }
    return state.translations[state.language];
}
}

export default getters;