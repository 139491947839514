<template>
  <v-container fluid grid-list-xl>
    <NotFound v-if="this.$store.getters.getAmountObject == 0"/>

    <v-layout wrap row align-center>

      <v-pagination
          :total-visible="7"
          style="margin: 0 auto"
          color="red"
          v-model="page"
          :length="this.$store.getters.getAmountPage"
          v-if="this.$store.getters.getPagination !== false &&
                      this.$store.getters.getAmountObject !== 0"
      ></v-pagination>

      <v-flex xs12 sm12 d-flex v-for="item in this.$store.getters.getSavePage">

        <v-layout wrap row align-center class="search-block" pa-4 mt-2>
          <v-flex xs12 sm8 d-flex>
            <h2>{{ item.object.pozadovanaProfese }}
              <p v-if="item.translated !== null"> {{ item.translated.translated_lang }}</p>
            </h2>
          </v-flex>

          <v-flex xs12 sm4 d-flex>
            <v-layout wrap row>
              <v-flex xs12 sm12 md12 lg12 d-flex>
                <v-btn small @click="copyToClipboard(item.object.referencniCislo)">
                  <v-icon style="margin-right: 2px;">content_paste</v-icon>
                  Скопировать в буфер
                </v-btn>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 d-flex style="align-self: center">
                {{ translations.placeholderNumber }}: {{ item.object.referencniCislo }}
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs8 sm8 d-flex>
            <h2>
              <p>
                <small v-if="item.object.mesicniMzdaOd !== null">От: {{ item.object.mesicniMzdaOd }}</small>
                <small v-if="item.object.mesicniMzdaDo !== null"> до {{ item.object.mesicniMzdaDo }}</small>
                <small v-if="item.object.mesicniMzdaDo !== null || item.object.mesicniMzdaOd !== null">
                  {{ getLabel('salaryType', item.object.typMzdy) }}
                </small>
              </p>
              <p>
                <h4 style="font-weight: 100;">
                  <h5 style="font-weight: 100;">{{ translations.pagination.company }}:
                    <span class="company-name">
                    {{
                        cutWithDots(item.object.mistoKontaktu, 5) | ucfirst | c2t
                      }}
                  </span>
                  </h5>
                </h4>
              </p>
            </h2>
          </v-flex>

          <v-flex xs12 sm6 d-flex>
            <span>{{ translations.pagination.timeOfCreation }}: {{ item.object.datumVlozeni | cut | ts2Date }}</span>
            <span>{{ translations.pagination.timeLastUpdate }}: {{ item.object.datumZmeny | cut | ts2Date }}</span>
          </v-flex>

          <v-flex xs12 sm6 d-flex>
            <CustomDialog :object_id="item.object.id"/>
          </v-flex>

        </v-layout>

      </v-flex>

      <v-pagination
          :total-visible="7"
          style="margin: 0 auto"
          color="red"
          v-model="page"
          :length="this.$store.getters.getAmountPage"
          v-if="this.$store.getters.getPagination !== false &&
                      this.$store.getters.getAmountObject !== 0"
      ></v-pagination>

    </v-layout>
  </v-container>
</template>

<script>
import NotFound from "@/components/NotFound";
import CustomDialog from "@/components/CustomDialog";
import moment from "moment";
import translit from "latin-to-cyrillic";

export default {
  name: "Search",
  data: function () {
    return {
      translations: this.$store.getters.getTranslate,
      page: null
    }
  },
  filters: {
    ts2Date: function (value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY");
      }
    },
    cut: function (value) {
      if (value) {
        return value.toString().slice(0, 10);
      }
    },
    c2t: function (value) {
      return translit(value);
    },
    ucfirst: function (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  },
  props: {
    msg: String
  },
  components: {
    NotFound,
    CustomDialog
  },
  watch: {
    'page': function (value) {
      this.page = value;
      this.$store.commit('saveCurrentPage', value);
      this.$store.commit('search')
    },
  },
  methods: {
    copyToClipboard(referenceNumber) {
      navigator.clipboard
          .writeText(location.origin + location.pathname + '#/' + referenceNumber)
    },
    cutWithDots(value, length = 10) {
      if (typeof value === 'undefined' || value === null) {
        return value;
      }
      let company = value.split(' ');
      if (company.length > 1) {
        return company[0];
      }
      return value.toString().slice(0, length) + '...';
    },
    getLabel(index, value) {
      for (let i = 0; i <= this.$store.getters.getMenuItem[index].length; i++) {
        if (this.$store.getters.getMenuItem[index][i]?.id === value) {
          return this.$store.getters.getMenuItem[index][i]?.translated_lang;
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.company-name {
  font-family: 'Lato', 'Roboto', Arial, Tahoma, sans-serif;
}

.search-block {
  background: #eee;
  outline: 1px solid #feec01;
}
</style>
