<template>
  <div class="text-xs-center custom-dialog">
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on }">
        <v-btn block v-on="on" @click="resetPanel" :id="object_id">
          {{ translations.pagination.moreInformation }}
        </v-btn>
      </template>

      <span v-if="dialog">
          <v-card>
            <v-card-title class="yellow lighten-3">
              <v-layout wrap row pa-2>
                <v-flex xs10 sm10 md10 lg11>
                  <h2 class="text-md-left text-sm-left dialog-heading">{{ getObject.pozadovana_profese }}
                    <h2 v-if="getObject.prof_name !== null" class="dialog-heading"> {{ getObject.prof_name }}</h2>
                  </h2>
                </v-flex>
                <v-flex xs2 sm2 md2 lg1 justify-end align-self-center>
                  <v-icon small color="orange darken-2" @click="dialog = false">close</v-icon>
                </v-flex>
              </v-layout>
            </v-card-title>

            <v-expansion-panel v-model="panel" class="scroll" expand focusable>
              <v-expansion-panel-content key="1">
                <template v-slot:header class="text-xs-center">
                  <h3 class="text-xs-center">{{ translations.customDialog.commonInfoTitle }}</h3>
                </template>
                <v-container fluid>
                <v-layout wrap row pa-2>
                      <v-flex xs6 sm6 md6 lg6 d-flex>
                        <h3>{{ translations.customDialog.company }}:</h3>
                      </v-flex>
                      <v-flex xs6 sm6 md6 lg6 d-flex>
                        <span class="text-center">
                          {{
                            cutWithDots(getObject.misto_kontaktu, 5) | ucfirst | c2t
                          }}
                       </span>
                      </v-flex>
                    </v-layout>
                <v-layout wrap row pa-2>
                <v-flex xs6 sm6 md6 lg6 d-flex>
                  <h3>{{ translations.customDialog.timeLastUpdate }}: </h3>
                </v-flex>
                <v-flex xs6 sm6 md6 lg6 d-flex>
                  <span class="text-center">
                    {{ getObject.datum_zmeny | cut | ts2Date }}
                  </span>
                </v-flex>
              </v-layout>
                <v-layout wrap row pa-2>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <h3>{{ translations.customDialog.placeholderNumber }}: </h3>
              </v-flex>
              <v-flex xs6 sm6 md6 lg6 d-flex>

                <v-layout wrap row pa-2>
                  <v-flex xs12 sm12 md3 lg2 d-flex style="align-self: center; justify-self: flex-start">
                  {{ getObject.referencni_cislo }}
                  </v-flex>
                  <v-flex xs12 sm12 md5 lg3 d-flex >
                    <v-btn style="font-size: 12px;" @click="copyToClipboard(getObject.referencni_cislo)">
                      <v-icon style="margin-right: 2px;">content_paste</v-icon>
                    Скопировать в буфер
                  </v-btn>
                  </v-flex>
                </v-layout>

              </v-flex>
            </v-layout>
                <v-layout wrap row pa-2>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <h3>{{ translations.customDialog.salary }}</h3>
              </v-flex>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <span class="text-center">
                <span v-if="getObject.mesicni_mzda_od !== null">От: {{ getObject.mesicni_mzda_od }}</span>
                <span v-if="getObject.mesicni_mzda_do !== null"> до {{ getObject.mesicni_mzda_do }}</span>
                <span v-if="getObject.mesicni_mzda_do !== null || getObject.mesicni_mzda_od !== null">
                  <span> {{ getSalaryType(getObject.typ_mzdy) }}</span>
                </span>
                </span>
              </v-flex>
            </v-layout>
                <v-layout wrap row pa-2>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <h3>{{ translations.customDialog.jobLocation }}</h3>
              </v-flex>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <v-layout wrap row>
                  <v-flex xs12 sm12 md12 lg12 d-flex>
                    <p class="text-center" v-for="adress in getAdress[0]">{{ adress }}</p>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12 d-flex>
                    <p><iframe v-if="getAdress.length !== 0" :src="addressLinkMap" width="600" height="450" style="border:0; width: 100%" allowfullscreen="true" loading="lazy"></iframe></p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
                <v-layout wrap row pa-2>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <h3>{{ translations.customDialog.amountVacancies }}</h3>
              </v-flex>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <p class="text-center">{{ getObject.pocet_mist }}</p>
              </v-flex>
            </v-layout>
                <v-layout wrap row pa-2>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <h3>{{ translations.customDialog.minimalEducation }}</h3>
              </v-flex>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <p>{{ getMinEducation(getObject.min_pozadovane_vzdelani) }}</p>
              </v-flex>
            </v-layout>
                <v-layout wrap row pa-2>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <h3>{{ translations.customDialog.lawyerRelation }}</h3>
              </v-flex>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <p v-for="vztah in getLabour(getObject.pracovne_pravni_vztah)">{{ vztah }}</p>
              </v-flex>
            </v-layout>
                <v-layout wrap row pa-2>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <h3>{{ translations.customDialog.hoursAtWeek }}</h3>
              </v-flex>
              <v-flex xs6 sm6 md6 lg6 d-flex>
                <p>{{ getObject.pocet_hodyn_tydne }}</p>
              </v-flex>
            </v-layout>
                <v-layout wrap row pa-2>
                  <v-flex xs6 sm6 md6 lg6 d-flex>
                    <h3>{{ translations.customDialog.shifts }}</h3>
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg6 d-flex>
                    <p>{{ getSmena(getObject.smennost) }}</p>
                  </v-flex>
                </v-layout>
                <v-layout wrap row pa-2>
                  <v-flex xs6 sm6 md6 lg6 d-flex>
                    <h3>{{ translations.customDialog.foreigners }}</h3>
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg6 d-flex>
                    <p>Да</p>
                  </v-flex>
                </v-layout>
                <v-layout wrap row pa-2>
                  <v-flex xs6 sm6 md6 lg6 d-flex>
                    <h3>{{ translations.customDialog.suitFor }}</h3>
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg6 d-flex>
                    <p v-for="vhodne in getSuitPro(getObject.vhodnost_pro_typ_zamestnance)">{{ vhodne }}</p>
                  </v-flex>
                </v-layout>
                <v-layout wrap row pa-2>
                  <v-flex xs6 sm6 md6 lg6 d-flex>
                    <h3>{{ translations.customDialog.requiredSkills }}</h3>
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg6 d-flex>
                    <p v-for="skill in getSkills(getObject.pozadovane_dovednosti)">{{ skill }}</p>
                  </v-flex>
                </v-layout>
                </v-container>
              </v-expansion-panel-content>

            <v-expansion-panel-content key="2">
              <template v-slot:header>
                <h3 class="text-xs-center">{{ translations.customDialog.additionalInfo }}:</h3>
              </template>
                <v-flex xs12 sm12 d-flex v-if="getObject.text.length > 3">
                  <v-layout wrap align-left row fill-height class="scroll">
                    <v-flex xs12 sm12 d-flex>
                      <div class="text-md-left text-wrap" v-html="getObject.text"></div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-expansion-panel-content>
          </v-expansion-panel>

      </v-card>
      </span>
    </v-dialog>
  </div>
</template>

<script>

import HTTP from "@/http";
import moment from "moment";
import translit from "latin-to-cyrillic";
import MD5 from "crypto-js/md5";

export default {
  name: "Home",
  props: ['object_id'],
  data: function () {
    return {
      translations: this.$store.getters.getTranslate,
      dialog: false,
      getObject: null,
      getAdress: [],
      addressLinkMap: null,
      salaryType: null,
      panel: [true, false]
    }
  },
  filters: {
    ts2Date: function (value) {
      if (typeof value === 'undefined' || value === null) {
        return value;
      }
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY");
      }
    },
    cut: function (value) {
      if (typeof value === 'undefined' || value === null) {
        return value;
      }
      if (value) {
        return value.toString().slice(0, 10);
      }
    },
    c2t: function (value) {
      if (typeof value === 'undefined' || value === null) {
        return value;
      }
      return translit(value);
    },
    ucfirst: function (value) {
      if (typeof value === 'undefined' || value === null) {
        return value;
      }
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  },
  watch: {
    'dialog': function (newValue) {
      if (newValue) {
        this.getData(this.object_id);
      }
    },
  },
  methods: {
    copyToClipboard(referenceNumber) {
      navigator.clipboard
          .writeText(location.origin + location.pathname + '#/' + referenceNumber)
    },
    cutWithDots(value, length = 10) {
      if (typeof value === 'undefined' || value === null) {
        return value;
      }
      let company = value.split(' ');
      if (company.length > 1) {
        return company[0];
      }
      return value.toString().slice(0, length) + '...';
    },
    getData() {
      HTTP.post(this.$store.state.host + '/vacancies/object?object_id=' + this.object_id, {object_id: this.object_id})
          .then((response) => {
            let object = response.data;
            object.prof_name = response.data.prof_name;
            object.text = response.data.text.replace(/\n/g, '<br />');
            this.getObject = object;
          });
      HTTP.post(this.$store.state.host + '/vacancies/adress?object_id=' + this.object_id, {object_id: this.object_id})
          .then((response) => {
            this.getAdress = this.processAdress(response.data);
          });
    },
    getSalaryType(value) {
      for (let i = 0; i < this.$store.getters.getMenuItem.salaryType.length; i++) {
        if (this.$store.getters.getMenuItem.salaryType[i].id === value) {
          return this.$store.getters.getMenuItem.salaryType[i].translated_lang;
        }
      }
    },
    getMinEducation(value) {
      for (let i = 0; i < this.$store.getters.getMenuItem.min_lvl_eduction.length; i++) {
        if (this.$store.getters.getMenuItem.min_lvl_eduction[i].id === value) {
          return this.$store.getters.getMenuItem.min_lvl_eduction[i].translated_lang;
        }
      }
    },
    getLabour(value) {
      let labourArray = [];
      for (let i = 0; i < this.$store.getters.getMenuItem.labor_relations?.length; i++) {
        if (this.$store.getters.getMenuItem.labor_relations[i].id === value) {
          labourArray.push(this.$store.getters.getMenuItem.labor_relations[i].translated_lang);
        }
      }
      return labourArray;
    },
    getSmena(value) {
      for (let i = 0; i < this.$store.getters.getMenuItem.smennost?.length; i++) {
        if (this.$store.getters.getMenuItem.smennost[i].id === value) {
          return this.$store.getters.getMenuItem.smennost[i].translated_lang;
        }
      }
    },
    getSuitPro(value) {
      let suitArray = [];
      for (let i = 0; i < this.$store.getters.getMenuItem.suitFor?.length; i++) {
        if (this.$store.getters.getMenuItem.suitFor[i].id === value) {
          suitArray.push(this.$store.getters.getMenuItem.suitFor[i].translated_lang);
        }
      }
      return suitArray;
    },
    getSkills(value) {
      let skillsArray = [];
      for (let i = 0; i < this.$store.getters.getMenuItem.skills?.length; i++) {
        let skill = this.$store.getters.getMenuItem.skills[i]
        if (skill.object_id === value) {
          skillsArray.push(skill.translated_lang);
        }
      }
      return skillsArray;
    },
    // eslint-disable-next-line no-unused-vars
    processAdress(adress) {
      if (adress === null) {
        return [];
      }
      this.addressLinkMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12345.67890123456!2d14.21598234976196!3d49.04712345678901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0x1234567890abcdef!2s" + encodeURI(adress[1]) + "!5e0!3m2!1sen!2sus!4v1634398083086!5m2!1sen!2sus";
      return adress;
    },
    resetPanel() {
      this.panel = [true, false];
    }
  }
};
</script>
<style>
.dialog-heading {
  font-size: 14px;
}

.v-card__title {
  padding: 3px 0 0 10px !important;
}

.v-dialog {
  overflow-y: visible !important;
}

.scroll {
  max-height: 60vh;
  overflow-y: auto;
}

#app-vacancies p {
  margin: 0px;
}
</style>