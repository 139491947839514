<template>

  <v-container fluid grid-list-xl>
    <v-layout wrap row align-center>
      <v-flex xs12 sm12 d-flex class="no-found">
        <h3>{{ this.$store.getters.getTranslate.notFound }}</h3>
      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>

export default {
  name: "Home",
};
</script>
